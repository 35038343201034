/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import PropTypes from 'prop-types';

interface Context {
  isHeaderActive: boolean;
  setIsHeaderActive(isOpen: boolean): void;
}

const SiteContext = React.createContext<Context>({
  isHeaderActive: false,
  setIsHeaderActive: (isHeaderActive: boolean) => {},
});

function SiteProvider({ children }) {
  const [isHeaderActive, setIsHeaderActive] = React.useState(false);

  return (
    <SiteContext.Provider
      value={{
        isHeaderActive,
        setIsHeaderActive,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
}

SiteProvider.propTypes = {
  children: PropTypes.node,
};

export { SiteContext, SiteProvider };
